// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-competition-edu-hub-js": () => import("./../../../src/pages/competition/EduHub.js" /* webpackChunkName: "component---src-pages-competition-edu-hub-js" */),
  "component---src-pages-competition-js": () => import("./../../../src/pages/Competition.js" /* webpackChunkName: "component---src-pages-competition-js" */),
  "component---src-pages-competition-rc-scholarship-js": () => import("./../../../src/pages/competition/RCScholarship.js" /* webpackChunkName: "component---src-pages-competition-rc-scholarship-js" */),
  "component---src-pages-competition-software-hub-autonomous-js": () => import("./../../../src/pages/competition/SoftwareHub/Autonomous.js" /* webpackChunkName: "component---src-pages-competition-software-hub-autonomous-js" */),
  "component---src-pages-competition-software-hub-dawn-guide-js": () => import("./../../../src/pages/competition/SoftwareHub/DawnGuide.js" /* webpackChunkName: "component---src-pages-competition-software-hub-dawn-guide-js" */),
  "component---src-pages-competition-software-hub-js": () => import("./../../../src/pages/competition/SoftwareHub.js" /* webpackChunkName: "component---src-pages-competition-software-hub-js" */),
  "component---src-pages-competition-software-hub-quickstart-guide-js": () => import("./../../../src/pages/competition/SoftwareHub/QuickstartGuide.js" /* webpackChunkName: "component---src-pages-competition-software-hub-quickstart-guide-js" */),
  "component---src-pages-competition-software-hub-student-skeleton-code-js": () => import("./../../../src/pages/competition/SoftwareHub/StudentSkeletonCode.js" /* webpackChunkName: "component---src-pages-competition-software-hub-student-skeleton-code-js" */),
  "component---src-pages-competition-software-hub-teleop-js": () => import("./../../../src/pages/competition/SoftwareHub/Teleop.js" /* webpackChunkName: "component---src-pages-competition-software-hub-teleop-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-get-involved-decal-js": () => import("./../../../src/pages/getInvolved/Decal.js" /* webpackChunkName: "component---src-pages-get-involved-decal-js" */),
  "component---src-pages-get-involved-forms-js": () => import("./../../../src/pages/getInvolved/Forms.js" /* webpackChunkName: "component---src-pages-get-involved-forms-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/GetInvolved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-get-involved-projects-js": () => import("./../../../src/pages/getInvolved/Projects.js" /* webpackChunkName: "component---src-pages-get-involved-projects-js" */),
  "component---src-pages-get-involved-schools-js": () => import("./../../../src/pages/getInvolved/Schools.js" /* webpackChunkName: "component---src-pages-get-involved-schools-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/Policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-support-us-js": () => import("./../../../src/pages/SupportUs.js" /* webpackChunkName: "component---src-pages-support-us-js" */)
}

